import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const newVisitor = true;

export default function Root() {
  const navigate = useNavigate();

  useEffect(() => {
    if (newVisitor) navigate(`/intro`);
  }, []);

  return <div>Welcome back</div>;
}
