import { useState } from "react";

export default function Report() {
  const [problem, setProblem] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");

  const submit = () => {
    setProblem("");
    setSubmissionMessage(
      "we're trying to solve your problem now. check back later"
    );
  };

  return (
    <div>
      <h1>Yo you got some kind of problem?</h1>
      <input
        type="text"
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
        placeholder="tell us about your problem"
      ></input>
      <button onClick={submit}>Submit problem</button>
      <p>{submissionMessage}</p>
    </div>
  );
}
