import { useState, useEffect, CSSProperties } from "react";
import { createMippi, getSpecies } from "../requests";
import { Link, ScrollRestoration } from "react-router-dom";

const images = {
  chestOnIce: require("../../assets/chest_on_ice.png"),
  eggs: {
    sapheroid: require("../../assets/egg_1_small.png"),
    glisteron: require("../../assets/egg_2_small.png"),
    luxuntium: require("../../assets/egg_3_small.png"),
  },
};

const cleanNumber = (phoneNumber) => {
  if (phoneNumber[0] === "0") return phoneNumber.slice(1);
  else return phoneNumber;
};

const projectLeads = [
  {
    name: "Professor Brainsworth",
    message:
      "Truly, without citizen scientists like yourself, professional scientists like myself would be stumbling in the dark, with no shoes or socks on, clattering into the furniture and irreparably damaging our shins.",
  },
  {
    name: "Addison Venture",
    message:
      "This XP has been totally dope so far. My thanks go out to one as rad as yourself helping us get our heads round these nutso eggs!",
  },
  {
    name: "Prudentia Croesus",
    message:
      " Great acts of industry oft start from small beginnings. We expect plentiful returns from your gracious act of unremunerated labour.",
  },
];

const LeadBubble = ({ lead, pictureLeft }) => {
  const [visible, setVisible] = useState(false);

  const orderStyle = { flexDirection: pictureLeft ? "row" : "row-reverse" };

  return (
    <div
      onClick={() => setVisible(!visible)}
      className="lead-bubble"
      style={orderStyle as CSSProperties}
    >
      <ScrollRestoration />
      <div>
        <p className="lead-picture">[picture of {lead.name}]</p>
        <p className="lead-name">{lead.name}</p>
      </div>
      {visible && <p>{lead.message}</p>}
    </div>
  );
};

export default function SignUp() {
  const [countryCode, setCountryCode] = useState("44");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [joined, setJoined] = useState(false);
  const [error, setError] = useState(false);

  const join = () => {
    setJoined(true);
    setError(true);
  };

  return (
    <div id="signup">
      <h1>Joint Antarctic Expedition: Volunteer Researcher Registration</h1>
      <p>
        The project leads of the Joint Antarctic Expedition would like to
        personally thank you for your interest in the volunteer research
        programme. Click on them to read their thank you message.
      </p>
      {projectLeads.map((lead, index) => (
        <LeadBubble lead={lead} pictureLeft={index % 2 === 0} />
      ))}
      {joined ? (
        error ? (
          <p>
            Our files record you as having already joined the volunteer
            programme. If there is some issue please report it via{" "}
            <Link to={"/report"}>the error form.</Link>
          </p>
        ) : (
          <p>
            Thank you for joining the JAE volunteer programme, our contact will
            be in touch with you shortly to transfer your research materials and
            provide your initial instructions.
          </p>
        )
      ) : (
        <>
          <p>
            Please enter your WhatsApp number below to join the volunteer
            programme.
          </p>
          <div id="phonenumber">
            <select
              id="country-code-input"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="44">+44</option>
              <option value="33">+33</option>
              <option value="1">+1</option>
            </select>
            <input
              id="phone-number-input"
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="WhatsApp number"
            ></input>
          </div>
          <div id="button-container">
            <button
              onClick={join}
              disabled={phoneNumber.length < 10}
              id="join-button"
            >
              Join
            </button>
          </div>
        </>
      )}
    </div>
  );
}

/*

export default function SignUp() {
  const [availableSpecies, setAvailableSpecies] = useState([]);
  const [countryCode, setCountryCode] = useState("44");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedSpecies, setSelectedSpecies] = useState(-1);
  const [bought, setBought] = useState(false);

  useEffect(() => {
    getSpecies()
      .then((response) => response.json())
      .then((species) => setAvailableSpecies(species));
  }, []);

  const buyMippi = async () => {
    if (phoneNumber && selectedSpecies > -1) {
      const response = await createMippi(
        countryCode + cleanNumber(phoneNumber),
        "unnamed",
        selectedSpecies
      );
      const result = await response.json();

      if (result.success) {
        setBought(true);
      }
    }
  };

  return (
    <div id="app-container">
      {bought ? (
        <>
          <h2>Great! We know you're going to have a great time!</h2>
          <p>mippi.pet will deliver your egg soon!</p>
        </>
      ) : (
        <>
          <h2 id="opening">
            We're explorers and we found this box in Antarctica after the ice
            melted.
          </h2>
          <img src={images.chestOnIce} id="chest-image" />
          <h2 id="post-image-text">
            There were loads of crazy eggs inside. Do you want one?
          </h2>
          {availableSpecies ? (
            <div id="species-container">
              {availableSpecies.map((species) => (
                <span key={species.id}>
                  <input
                    type="radio"
                    className="species-select-radio"
                    id={species.id}
                    name="species"
                    value={species.id}
                    onChange={(e) => {
                      if (selectedSpecies === -1) {
                        setTimeout(() => window.scrollBy(0, 1000), 100);
                      }
                      setSelectedSpecies(parseInt(e.target.value));
                    }}
                  ></input>
                  <label
                    htmlFor={species.id}
                    className={`species-select ${
                      species.id === selectedSpecies ? "active" : "inactive"
                    }`}
                  >
                    <img
                      src={images.eggs[species.egg_name.toLowerCase()]}
                      className="egg-image"
                      id={`image-egg-${species.egg_name}`}
                    />
                    <h3 className="species-name">{species.egg_name}</h3>
                  </label>
                  <br />
                </span>
              ))}
            </div>
          ) : (
            <h3>Loading available Mippis</h3>
          )}
          {selectedSpecies > -1 && (
            <>
              <h2 id="success-message">Nice choice!</h2>
              <h2>We'll send your egg to you on WhatsApp:</h2>
              <div>
                <select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="44">+44</option>
                  <option value="33">+33</option>
                  <option value="1">+1</option>
                </select>
                <input
                  id="phone-number-input"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="WhatsApp number"
                ></input>
              </div>
              <button
                onClick={buyMippi}
                disabled={phoneNumber.length < 10 || selectedSpecies < 0}
                id="buy-button"
              >
                Get my egg
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}
*/
