import { Link } from "react-router-dom";

const NewspaperClipping = ({ headline, article, picture, rotation }) => {
  const rotationStyle = { transform: `rotate(${rotation}deg)` };

  return (
    <div className="clipping" style={rotationStyle}>
      <h1 className="title">Daily Exposition</h1>
      <p className="tagline">We'll tell you the necessary information.</p>
      <h2 className="headline">{headline}</h2>
      <h2>[{picture}]</h2>
      <p className="article">{article}</p>
    </div>
  );
};

export default function Intro() {
  const clippings = [
    {
      headline: "RECORD ANTARCTIC ICE MELT REVEALS ANCIENT RUINS",
      article:
        "Satellite images of Antarctica after a record heatwave in the Southern Hemisphere reveal remnants of a previously unknown civilisation.",
      picture: "satellite image of antarctica",
    },
    {
      headline: "ANTARCTIC RUINS: JOINT EXPEDITION FORMED",
      article:
        "Boffinbridge University and the Riskyman Society, with financial backing from the Richmond Moneyman Fund, have formed an expedition to explore the ruins recently found in Antarctica.",
      picture: "picture of group outside University",
    },
    {
      headline: "MAJOR DISCOVERY IN ANTARCTICA",
      article:
        "The Joint Antarctic Expedition reports that they have discovered a chamber containing thousands of large frozen eggs. “These eggs are from a totally unknown species” reports Professor Brainsworth, 53, scientific lead of the JAE. “We've never seen anything like them before.”",
      picture:
        "picture of Brainsworth holding an egg, in the chamber with the eggs",
    },
    {
      headline: "EXPEDITION CALLS FOR RESEARCH VOLUNTEERS",
      article:
        "The Joint Antarctic Expedition has announced a new volunteer programme to help determine the true nature of the Antarctic eggs. “We need citizen science like never before” said Professor Brainsworth, 53, the scientific lead of the JAE, “I urge anyone considering joining the effort to do so with utmost haste. This could be the breakthrough of the millennium!”",
      picture: "picture of researcher with question mark for head",
    },
  ];

  return (
    <div id="intro-container">
      {clippings.map((clipping, index) => (
        <NewspaperClipping
          key={clipping.headline}
          headline={clipping.headline}
          article={clipping.article}
          picture={clipping.picture}
          rotation={((index % 2) * 2 - 1) * 15}
        />
      ))}
      <p className="question">Will you answer the call?</p>
      <Link to={"/signup"}>
        <p className="answer">Of course!</p>
      </Link>
      <a href="www.loser.com">
        <p className="answer">No.</p>
      </a>
    </div>
  );
}
