import "dotenv/config";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/Root";
import SignUp from "./routes/SignUp";
import ErrorPage from "./routes/ErrorPage";
import Intro from "./routes/Intro";
import Report from "./routes/Report";

import "./styles/app.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "intro",
    element: <Intro />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "report",
    element: <Report />,
  },
]);

const root = createRoot(document.getElementById("app"));
root.render(<RouterProvider router={router} />);
